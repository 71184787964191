import type { ParamsType, QueryType, RoutesType } from '@/interfaces/routes';
import { Zone } from '@/interfaces/services';

//* exemple: { key1: "value1", key2: 15 }
export const createRouteWithQueries = (
  route: string,
  queries?: QueryType,
): string => {
  if (queries === undefined || queries === null) {
    return route;
  }

  const searchParams = new URLSearchParams();

  for (const key in queries) {
    if (
      Object.prototype.hasOwnProperty.call(queries, key) &&
      queries[key] !== undefined
    ) {
      searchParams.append(key, queries[key]?.toString());
    }
  }

  const qs = searchParams.toString();

  return qs ? `${route}?${qs}` : route;
};

//* exemple: ["value1", 15]
export const createRouteWithParams = (
  route: string,
  params?: ParamsType,
): string => {
  if (!params) {
    return route;
  }

  const customParams = Array.isArray(params) ? params : [params];

  const ps = customParams
    .map((v) => (v ? encodeURIComponent(v.toString()) : ''))
    .filter((v) => v)
    .join('/');

  return ps ? `${route}/${ps}` : route;
};

const routes: RoutesType = {
  pages: {
    home: () => '/dashboard',
    signOut: () => '/signOut',
    login: (query?: QueryType) => createRouteWithQueries('/', query),
    register: (query?: QueryType) => createRouteWithQueries('/register', query),
    // TODO: A faire:
    profil: () => '/profil',
    password: () => '/reset-password',
    reset: (query?: QueryType) =>
      createRouteWithQueries('/reset-password/new', query),
    pro: (query?: QueryType) => createRouteWithQueries('/reseaux-pro', query),
    reseauxResult: (params?: ParamsType) =>
      createRouteWithParams('/candidats/pro', params),
    // TODO: A faire:
    cv: (query?: QueryType) => createRouteWithQueries('/cv', query),
    cvResult: (params?: ParamsType) =>
      createRouteWithParams('/candidats/cv', params),
    // TODO: A faire:
    // prospect: () => '/prospects',
    favoris: (params?: ParamsType) => createRouteWithParams('/favoris', params),
    searchFavoris: (query?: QueryType) =>
      createRouteWithQueries('/favoris', query),
    searches: (params?: ParamsType) =>
      createRouteWithParams('/searches', params),
    // TODO: A faire:
    // TODO: A faire:
    news: () => 'https://talaryo.com',
    // TODO: A faire:
    blog: () => 'https://talaryo.com',
    unauthorized: () => '/unauthorized',
    billing: (query?: QueryType) => createRouteWithQueries('/billing', query),
    paidSuccess: () => '/payment/successful',
    //* admin
    userOffice: (query?: QueryType) =>
      createRouteWithQueries('/backoffice-users', query),
    trainingIa: (query?: QueryType) =>
      createRouteWithQueries('/trainingIA', query),
  },
  api: {
    // * Admin
    trainingIA: (query?: QueryType) =>
      createRouteWithQueries('/trainingIA', query),
    // * Auth
    register: () => '/register',
    googleLogin: () => '/OAuth',
    login: () => '/login',
    // * users
    confirmAccount: () => '/validate-account',
    resetPassword: (params?: ParamsType) =>
      createRouteWithParams('/asking-reset-password', params),
    checkingReset: (params?: ParamsType) =>
      createRouteWithParams('/checking-reset-password', params),
    newPassword: () => '/confirm-reset-password',
    updateCurrentUser: () => '/update-current-user',
    updateUsers: () => '/update-users',
    allUsers: (query?: QueryType) =>
      createRouteWithQueries('/get-all-users', query),
    // * subscribe
    getCurrentSub: () => '/get-subscription',
    updateSub: (query?: QueryType) =>
      createRouteWithQueries('/update-subscription', query),
    cancelSub: () => '/cancel-subscription',
    createSub: (params?: ParamsType) =>
      createRouteWithParams('/create-subscription', params),
    getInvoices: () => '/get-Invoices',
    // * scores
    addingScore: (query?: QueryType) =>
      createRouteWithQueries('/profiles-consulted', query),
    getScores: (params?: ParamsType) =>
      createRouteWithParams('/get-user-score', params),
    getAllScores: () => '/get-user-score/true',
    getAmountScores: () => '/get-amount-score',
    getTotalScores: () => '/get-total-scores',
    // * Scrapping
    communeCodeLoc: (z: Zone) =>
      `https://geo.api.gouv.fr/communes?codePostal=${encodeURIComponent(z.search)}&fields=nom,codesPostaux,departement,region`,
    communeNameLoc: (z: Zone) =>
      `https://geo.api.gouv.fr/communes?nom=${encodeURIComponent(z.search)}&fields=nom,codesPostaux,departement,region`,
    regionCodeLoc: (z: Zone) =>
      `https://geo.api.gouv.fr/regions?code=${encodeURIComponent(z.search)}&fields=nom`,
    regionNameLoc: (z: Zone) =>
      `https://geo.api.gouv.fr/regions?nom=${encodeURIComponent(z.search)}&fields=nom`,
    scrapSearch: (query?: QueryType) =>
      createRouteWithQueries('/scrapping', query),
    scrapCV: (query?: QueryType) =>
      createRouteWithQueries('/scrapping-cv', query),
    cvContent: (query?: QueryType) =>
      createRouteWithQueries('/cv-content', query),
    scrapMail: (query?: QueryType) =>
      createRouteWithQueries('/mailer-scrape', query),
    // * Favoris
    addFavori: () => '/create-fav',
    updateFavori: () => '/update-fav',
    getFavoris: (query?: QueryType) =>
      createRouteWithQueries('/get-favoris', query),
    removeFavoris: (params?: ParamsType) =>
      createRouteWithParams('/remove-fav', params),
    getLastFavoris: (query?: QueryType) =>
      createRouteWithQueries('/get-lastFavoris', query),
    // * FavFolders
    addFavFolder: () => '/create-favFolders',
    removeFavFolder: (params?: ParamsType) =>
      createRouteWithParams('/remove-favFolders', params),
    getFavFolders: (query?: QueryType) =>
      createRouteWithQueries('/get-favFolders', query),
    //  * SearchFolder
    addSearchFolder: () => '/create-searchFolders',
    removeSearchFolder: (params?: ParamsType) =>
      createRouteWithParams('/remove-searchFolders', params),
    getSearchFolders: (query?: QueryType) =>
      createRouteWithQueries('/get-searchFolders', query),
    //  * Search
    addSearch: (query?: QueryType) =>
      createRouteWithQueries('/create-search', query),
    getSearches: (query?: QueryType) =>
      createRouteWithQueries('/get-searches', query),
    getLastSearches: (query?: QueryType) =>
      createRouteWithQueries('/get-lastSearches', query),
    getTotalSearches: (params?: ParamsType) =>
      createRouteWithParams('/get-totalSearches', params),
    removeSearch: (params?: ParamsType) =>
      createRouteWithParams('/remove-search', params),
  },
  //? const searchRoute: queryRoutesType = routes.exports('search')
  exports: (route) => routes.pages[route],
};

export default routes;
