'use client';
import cn from '@/utils/cn';
import { toast, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Notification(
  {
    text,
    timer,
    err,
    pos,
    classNames,
  }: {
    text?: string;
    timer?: number;
    err?: string;
    pos?: ToastPosition;
    classNames?: { main?: string; body?: string; progress?: string };
  } = {
    text: "Cette fonctionnalité n'est pas encore disponible.",
    timer: 3000,
    err: undefined,
  },
) {
  if (err) {
    return toast.warn(err, {
      toastId: 'unique',
      position: pos || 'bottom-left',
      autoClose: timer,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      className: (props) =>
        cn(
          props?.defaultClassName,
          '!text-errorTxt/90 !bg-content/80 !rounded-lg toastUIErr border-1 border-b-0 border-errorBg/50',
          classNames?.main,
        ),
      bodyClassName: cn('space-x-2', classNames?.body),
      progressClassName: (props) =>
        cn(props?.defaultClassName, '!bg-errorTxt/70', classNames?.progress),
    });
  }
  toast.info(text, {
    toastId: 'unique',
    position: pos || 'bottom-left',
    autoClose: timer,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: (props) =>
      cn(
        props?.defaultClassName,
        '!text-foreground/90 !bg-content !rounded-lg toastUI border-1 border-b-0 border-asset/20',
        classNames?.main,
      ),
    bodyClassName: cn('space-x-2', classNames?.body),
    progressClassName: (props) =>
      cn(props?.defaultClassName, '!bg-foreground/70', classNames?.progress),
  });
}
